import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const HeaderComponent = () => {
    return (
        <header className="container my-4">
            <div className="row">
                <div className="col">
                    <h1>Doppelkopf Spielzettel</h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Link to="/jahresliste">← zur Jahresliste</Link>
                </div>
            </div>
        </header>
    )
}

const FooterComponent = () => {
    return (
        <footer className="container">
            <div className="row mb-4">
                <div className="col text-center">
                    <a href="https://kpelz.eu" target="_blank" rel="noopener noreferrer">© 2019-{new Date().getFullYear()} Konstantin Pelz</a>
                </div>
            </div>
            <div className="row my-4 rotate180">
                <div className="col">
                    <h1>Doppelkopf Spielzettel</h1>
                </div>
            </div>
        </footer>
    )
}

class Spielzettel extends Component {

    render() {
        return (
            <div id="spielzettel">
                <HeaderComponent />
                <FooterComponent />
            </div>
        )
    }

}

export default Spielzettel;