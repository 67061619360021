import React, { Component } from 'react';

const PlayersTableHeader = () => {
    return (
        <div className="row">
            <div className="col">
                <h4>Bestenliste</h4>
            </div>
        </div>
    )
}

const TableHeader = () => {
    return (
        <thead>
            <tr>
                <th>Name</th>
                <th>Punkte</th>
            </tr>
        </thead>
    )
}

const TableBody = (props) => {
    const rows = props.playersData.map((row) => {
        return (
            <tr key={row.name}>
                <td>{row.name}</td>
                <td>{row.score}</td>
            </tr>
        )
    })
    return <tbody>{rows}</tbody>
}

class PlayersTable extends Component {

    render() {
        const { playersData } = this.props;
        return (
            <div className="container mb-4">
                <PlayersTableHeader />
                <div className="row">
                    <div className="col">
                        <table className="table table-hover table-borderless">
                            <TableHeader />
                            <TableBody playersData={playersData} />
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default PlayersTable