import React, { Component } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Jahresliste from './Jahresliste';
import Spielzettel from './Spielzettel';

class App extends Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/spielzettel/:id">
                        <Spielzettel />
                    </Route>
                    <Route path="/">
                        <Jahresliste />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default App