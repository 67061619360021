import React, { Component } from 'react';
import PlayersTable from './PlayersTable';
import GamesList from './GamesList';

const HeaderComponent = () => {
    return (
        <header className="container my-4">
            <div className="row">
                <div className="col">
                    <h1>Doppelkopf Jahresliste</h1>
                </div>
            </div>
        </header>
    )
}

const FooterComponent = () => {
    return (
        <footer className="container">
            <div className="row mb-4">
                <div className="col text-center">
                    <a href="https://kpelz.eu" target="_blank" rel="noopener noreferrer">© 2019-{new Date().getFullYear()} Konstantin Pelz</a>
                </div>
            </div>
            <div className="row my-4 rotate180">
                <div className="col">
                    <h1>Doppelkopf Jahresliste</h1>
                </div>
            </div>
        </footer>
    )
}

class Jahresliste extends Component {

    state = {
        data: {
            games: [],
            players: []
        }
    }

    componentDidMount = () => {
        fetch('/api/games?year=' + new Date().getFullYear())
            .then(result => result.json())
            .then(json => {
                this.setState({
                    data: {
                        games: json,
                        players: getPlayers(json)
                    }
                });
            });
    }

    handleGameSubmit = (game) => {
        console.log('handle submit');
        console.log(game);

        if (game.id) {
            fetch('/api/games/' + game.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(game)
            });
            let games = this.state.data.games.map((g) => {
                if (g.id === game.id) {
                    return game;
                }
                return g;
            });
            this.setState({
                data: {
                    games: games,
                    players: getPlayers(games)
                }
            });
        } else {
            fetch('/api/games', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(game)
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        data: {
                            games: [...this.state.data.games, json],
                            players: getPlayers([...this.state.data.games, json])
                        }
                    });
                });
        }
    }

    handleGameDelete = (game) => {
        fetch('/api/games/' + game.id, {
            method: 'DELETE',
        }).then((res) => {
            if (res.ok) {
                let games = this.state.data.games;
                console.log(games);
                games = games.filter((g) => g.id !== game.id);
                console.log(games);
                this.setState({
                    data: {
                        games: games,
                        players: getPlayers(games)
                    }
                });
            }
        });
    }

    render() {
        return (
            <div id="jahresliste">
                <HeaderComponent />
                <PlayersTable playersData={this.state.data.players} />
                <GamesList gamesData={this.state.data.games} handleSubmit={this.handleGameSubmit} handleDelete={this.handleGameDelete} />
                <FooterComponent />
            </div>
        )
    }
}

function getPlayers(games) {
    let players = [];
    for (let i in games) {
        let g = games[i]
        for (let j in g.players) {
            let p = g.players[j];
            let player = players.find((elem) => elem.name === p.name);
            if (player) {
                player.score += Number(p.score);
            } else {
                player = {
                    name: p.name,
                    score: Number(p.score)
                };
                players.push(player);
            }
        }
    }
    players.sort((a, b) => b.score - a.score);
    return players;

}

export default Jahresliste;
