import React, { Component } from 'react';

class DeleteGameModal extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            game: {
                date: {},
                id: '',
                place: '',
                players: [],
                timestamp: ''
            }
        }

        this.state = this.initialState;
    }

    handleGame(nGame) {
        this.setState({
            game: nGame
        });
    }

    render() {
        const { timestamp, place } = this.state.game;

        return (
            <div className="modal fade" id="delete-game-modal" tabIndex="-1" role="dialog" aria-labelledby="delete-game-dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Spiel löschen?</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4>{new Date(timestamp).toDateString() + ' ' + place}</h4>
                            <h4>Willst du dieses Spiel wirklich löschen?</h4>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                data-dismiss="modal">
                                Doch nicht
                                </button>
                            <button
                                type="button"
                                className="btn btn-outline-red"
                                data-dismiss="modal"
                                onClick={() => this.props.handleDelete(this.state.game)}>
                                Spiel löschen
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteGameModal