import React, { Component } from 'react';
import $ from 'jquery';

class EditGameModal extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            game: {
                timestamp: new Date().toISOString().split('T')[0],
                place: '',
                players: [{
                    name: '',
                    score: 0
                }, {
                    name: '',
                    score: 0
                }, {
                    name: '',
                    score: 0
                }, {
                    name: '',
                    score: 0
                }],
            },
            validateErrors: ''
        }

        this.state = this.initialState;
    }

    handleGame(game) {
        this.setState({
            game: game,
            validateErrors: ''
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let game = this.state.game;
        game[name] = value;
        this.setState({
            game: game
        });
    }

    handlePlayerDetailsChange = (event, idx) => {
        const { name, value } = event.target;
        let newPlayers = this.state.game.players.map((player, pidx) => {
            if (idx !== pidx) return player;
            return { ...player, [name]: value };
        });
        let game = this.state.game;
        game.players = newPlayers;
        this.setState({
            game: game
        });
    }

    addPlayer = () => {
        let game = this.state.game;
        game.players = [...this.state.game.players, { name: '', score: 0 }];
        this.setState({
            game: game
        });
    }

    removePlayer = (idx) => {
        let players = this.state.game.players;
        players.splice(idx, 1);
        let game = this.state.game;
        game.players = players;
        this.setState({
            game: game
        });
    }

    submitForm = () => {
        let form = document.getElementById('game-form');
        form.classList.remove('was-validated');
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            return;
        }
        fetch('/api/games/validate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.game)
        }).then((res) => {
            if (res.ok) {
                this.props.handleSubmit(this.state.game);
                $('#edit-game-modal').modal('hide');
                this.setState(this.initialState);
            } else {
                res.json().then((json) => {
                    this.setState({
                        validateErrors: json
                    });
                });
            }
        });
    }

    render() {
        const { timestamp, place, players } = this.state.game;
        const validateErrors = this.state.validateErrors;

        return (
            <div className="modal fade" id="edit-game-modal" tabIndex="-1" role="dialog" aria-labelledby="game-dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Spieldialog</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form novalidate id="game-form">
                                <div className="form-group">
                                    <label for="timestamp">Datum</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="timestamp"
                                        id="timestamp"
                                        value={timestamp}
                                        onChange={this.handleChange}
                                        required />
                                    <div className="invalid-feedback">Denk an das Datum</div>
                                </div>
                                <div className="form-group">
                                    <label for="place">Ort des Geschehens</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="place"
                                        id="place"
                                        placeholder="Wo habt ihr gespielt?"
                                        value={place}
                                        onChange={this.handleChange}
                                        required />
                                    <div className="invalid-feedback">Denk an den Ort</div>
                                </div>
                                {players.map((elem, idx) => {
                                    return (
                                        <div class="form-group row">
                                            <div className="col-7">
                                                <label for={"playerName" + idx}>Spieler*in {idx + 1}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id={"playerName" + idx}
                                                    value={elem.name}
                                                    onChange={(evt) => this.handlePlayerDetailsChange(evt, idx)}
                                                    required />
                                                <div className="invalid-feedback">Denk an den Namen</div>
                                            </div>
                                            <div className="col-3 pl-0">
                                                <label for={"playerScore" + idx}>Punkte</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="score"
                                                    id={"playerScore" + idx}
                                                    value={elem.score}
                                                    onChange={(evt) => this.handlePlayerDetailsChange(evt, idx)}
                                                    required />
                                                <div className="invalid-feedback">Denk an die Punkte</div>
                                            </div>
                                            <div className="col-2 pl-0 d-flex align-items-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary btn-block"
                                                    onClick={() => this.removePlayer(idx)}>
                                                    &times;</button>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className=" form-group row">
                                    <div className="col d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-outline-red"
                                            onClick={this.addPlayer}>
                                            + Spieler*in</button>
                                    </div>
                                </div>
                                <div className="form-group row custom-control custom-checkbox">
                                    <div className="col">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="geloebnis"
                                            required />
                                        <label className="custom-control-label" for="geloebnis">
                                            Ich gelobe feierlich, dass ich nicht betrogen habe
                                        </label>
                                        <div className="invalid-feedback">Ähh hallo?!</div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col">
                                        <p className="text-danger">{validateErrors}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Doch nicht</button>
                            <button type="button" className="btn btn-outline-red" onClick={this.submitForm}>Spiel speichern</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditGameModal