import React, { Component } from 'react';
import EditGameModal from './EditGameModal';
import DeleteGameModal from './DeleteGameModal';

const GamesHeader = (props) => {
    const newGame = {
        timestamp: new Date().toISOString().split('T')[0],
        place: '',
        players: [{
            name: '',
            score: 0
        }, {
            name: '',
            score: 0
        }, {
            name: '',
            score: 0
        }, {
            name: '',
            score: 0
        }]
    }
    return (
        <div className="row mb-2">
            <div className="col-9">
                <h3>Alle Spiele</h3>
            </div>
            <div className="col-3">
                <button
                    type="button"
                    className="btn btn-outline-red btn-block"
                    data-toggle="modal"
                    data-target="#edit-game-modal"
                    onClick={() => props.newGame(props.$this, newGame)}>
                    <b>+</b>
                </button>
            </div>
        </div>
    )
}

const GamesBoxes = (props) => {
    const boxes = props.gamesData.map((box, idx) => {
        let headerId = 'heading' + idx;
        let collapseId = 'collapse' + idx;
        let boxDate = new Date(box.timestamp).toDateString();
        let boxPlace = box.place;
        return (
            <div className="card">
                <div id={headerId} className="card-header">
                    <div className="text-left">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-parent="#gamesAccordion" data-target={"#" + collapseId} aria-expanded="false" aria-controls={collapseId}>
                                {boxDate + " " + boxPlace}
                            </button>
                        </h2>
                    </div>
                </div>
                <div id={collapseId} className="collapse" aria-labelledby={headerId} data-parent="#gamesAccordion">
                    <div className="card-body">
                        <GameTable gameData={box} />
                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-outline-red mr-2"
                                data-toggle="modal"
                                data-target="#delete-game-modal"
                                onClick={() => props.deleteGame(props.$this, box)}>
                                Löschen
                                </button>
                            <button
                                type="button"
                                className="btn btn-outline-red"
                                data-toggle="modal"
                                data-target="#edit-game-modal"
                                onClick={() => props.editGame(props.$this, box)}>
                                Editieren
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    });
    return (
        <div id="gamesContainer">
            <div id="gamesList" className="row">
                <div className="col">
                    <div className="accordion" id="gamesAccordion">
                        {boxes}
                    </div>
                </div>
            </div>
        </div>
    )
}

const GameTable = (props) => {
    const rows = props.gameData.players
        .sort((a, b) => b.score - a.score)
        .map((elem, idx) => {
            let emoji = '';
            if (idx === 0) {
                emoji = "🥇";
            } else if (idx === 1) {
                emoji = "🥈";
            } else if (idx === 2) {
                emoji = "🥉";
            } else if (idx === props.gameData.players.length - 1) {
                emoji = "🍺";
            }
            return (
                <tr>
                    <td>
                        {emoji + elem.name}
                    </td>
                    <td>
                        {elem.score}
                    </td>
                </tr>
            )
        });
    return (
        <table className="table table-hover table-borderless">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Punkte</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table >
    )
}

class GamesList extends Component {

    constructor(props) {
        super(props);
        this.editGameModal = React.createRef();
        this.deleteGameModal = React.createRef();
    }

    updateEditGameModal($this, game) {
        game.timestamp = game.timestamp.split('T')[0];
        $this.editGameModal.current.handleGame(game);
    }

    updateDeleteGameModal($this, game) {
        $this.deleteGameModal.current.handleGame(game);
    }

    render() {
        const { gamesData, handleSubmit, handleDelete } = this.props;
        return (
            <div className="container mb-4">
                <GamesHeader $this={this} newGame={this.updateEditGameModal} />
                <GamesBoxes $this={this} gamesData={gamesData} editGame={this.updateEditGameModal} deleteGame={this.updateDeleteGameModal} />
                <EditGameModal ref={this.editGameModal} handleSubmit={handleSubmit} />
                <DeleteGameModal ref={this.deleteGameModal} handleDelete={handleDelete} />
            </div>
        )
    }
}

export default GamesList